import {
  Engine, FreeCamera,
  HemisphericLight, MeshBuilder,
  Scene,
  Vector3,
  WebXRDefaultExperience,
} from 'babylonjs'
import "babylonjs-loaders"
import { log }from "./util"
import * as room from './room'

const canvas = document.getElementById('view') as HTMLCanvasElement
const engine = new Engine(canvas, true, {
  xrCompatible: true, 
  // stencil: true,
})
window.engine = engine

declare global {
  interface Window { 
    engine: Engine
    xr: WebXRDefaultExperience
    scene: Scene
    client: room.RoomClient
  }
}

async function main() {
  log('v4')

  const scene = new Scene(engine)
  window.scene = scene

  // scene.detachControl()

  log('scene', scene)
  const camera = new FreeCamera('camera1', new Vector3(2,1.5,-1), scene)
  camera.setTarget(new Vector3(0, 1.6, 0))
  // camera.attachControl(canvas, false)

  // const ground = MeshBuilder.CreateGround('ground1', { width: 12, height: 12, subdivisions:2, updatable: false}, scene)
  const xr = await scene.createDefaultXRExperienceAsync({
      // floorMeshes: [ground],
      // handSupportOptions: {
      // },
      // disableTeleportation: true,
  })
  window.xr = xr

  const featuresManager = xr.baseExperience.featuresManager

  const light = new HemisphericLight('light1', new Vector3(0,3,0), scene)
  
  const sphere = MeshBuilder.CreateSphere('sphere1', { segments: 12, diameter: 2 }, scene)
  sphere.position.y = 3
  sphere.renderingGroupId = 1

  engine.runRenderLoop(() => {
    // inputs.tick()
    scene.render()
  })
  window.addEventListener('resize', () => engine.resize())
  window.addEventListener("keydown", (ev: KeyboardEvent) => {
    if (ev.shiftKey && ev.ctrlKey && ev.altKey && ev.code === 'KeyI') {
      if (scene.debugLayer.isVisible()) {
        scene.debugLayer.hide()
      } else {
        scene.debugLayer.show()
      }
    }
    if (!ev.shiftKey && !ev.ctrlKey && !ev.altKey && ev.code === 'KeyW') {
      const camera = scene.activeCamera!
      const pos = Vector3.TransformCoordinates(new Vector3(0,0,0.2), camera.getWorldMatrix())
      camera.position.copyFrom(pos)
    }
    if (!ev.shiftKey && !ev.ctrlKey && !ev.altKey && ev.code === 'KeyS') {
      const camera = scene.activeCamera!
      const pos = Vector3.TransformCoordinates(new Vector3(0,0,-0.2), camera.getWorldMatrix())
      camera.position.copyFrom(pos)
    }
  })

  const client = window.client = room.connect('test', {
    init({id,start}:{id: number, start: number}) {
      log('room initialized', id, start)
    },
    pong({start, offset}:{start: number, offset: number}) {
      const rtt = performance.now() - start
      console.log(`ping = ${rtt}, offset = ${offset}`)
    },
    _else(data: any) {
      console.log('weird message', data._type)
    },
  })

  setInterval(() => {
    client.emit('ping', {
      start: performance.now(),
    })
  }, 5000)
}

main()