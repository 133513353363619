import { log, safeResult } from "./util"

declare global {
  interface Window {
    socket: WebSocket
    connect(room: string, handlers: Record<string, Function>): RoomClient
  }
}

export interface RoomClient {
  on(listeners: Record<string, Function>): void
  emit(event:string, data: any): void
  close(): void
}

export function connect(room: string, handlers: Record<string, Function>): RoomClient {
  log("connecting to room", room)
  // If we are running via wrangler dev, use ws:
  const url = new URL(document.URL)
  url.protocol = url.protocol === "http:" ? "ws:" : "wss:"
  url.pathname = `/api/room/${room}`
  const socket = new WebSocket(url.toString())
  window.socket = socket
  const start = Date.now()

  function setHandlers(added: Record<string, Function>) {
    Object.assign(handlers, added)
  }

  function emit(event: string, data: any) {
    socket.send(JSON.stringify({_type: event, ...data}))
  }

  function close() {
    socket.close(1234,"done")
  }

  function on(listeners: Record<string, (event: Event) => void>) {
    for (const [name, handler] of Object.entries(listeners)) {
      socket.addEventListener(name, handler)
    }
  }

  // attach listeners
  on({
    open(event) {
      log('opened')
    },

    message(event) {
      let data = JSON.parse((event as MessageEvent).data);
      const _type = data._type
      const f = handlers[_type] || handlers['_else']
      f && safeResult(f(data))
    },

    close(ev) {
      const event = ev as CloseEvent
      log("closed", event.code, event.reason)
    },

    error(event) {
      log("error", event);
    }
  })

  return {
    on: setHandlers,
    emit,
    close,
  }
}
window.connect = connect